
body {
	padding: 0;
	margin: 0;
	font-size: 18px;
	font-family: 'Google Sans', sans-serif;
    overflow-x: hidden;
}

img {
	max-width: 100%;
	height: auto;
	border-radius: 3px;
}

button {
	outline: 0 !important;
}

dl, ol, ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.d-table {
	width: 100%;
	height: 100%;
}

.d-table-cell {
	vertical-align: middle;
}

.ptb-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}

.pb-50 {
	padding-bottom: 50px;
}
.pb-70 {
	padding-bottom: 70px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pt-50 {
	padding-top: 50px;
}
.pt-70 {
	padding-top: 70px;
}
.pt-100 {
	padding-top: 100px;
}

.bg-grey {
	background-color: rgb(215, 215, 215, 0.17);
	position: relative;
	/* background: url(../img/grey-bg.jpg); */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	z-index: 1;
}

.section-padding {
	padding: 100px 0;
}

a {
	text-decoration: none;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	outline: 0 !important;
	color: #505060;
}

a:hover {
	text-decoration: none;
	color: #01a7ec;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	line-height: 1.4;
	font-family: 'Google Sans', sans-serif;
}

p {
	font-size: 18px;
	line-height: 1.8;
	color: #000;
	margin-bottom: 18px;
	font-weight: 400;
	letter-spacing: 0.3px;
	font-family: 'Google Sans', sans-serif;
	text-align: justify;
}

p:last-child {
	margin-bottom: 0;
}

.default-btn {
	border: none;
	position: relative;
	display: inline-block;
	text-align: center;
	overflow: hidden;
	z-index: 1;
	color: #ffffff;
	background-image: linear-gradient(177deg, #01a7ec 0%, #098fc7 100%);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 30px;
	font-weight: 600;
	font-size: 15px;
	padding-left: 35px;
	padding-right: 35px;
	padding-top: 12px;
	padding-bottom: 12px;
}

.default-btn span {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-radius: 50%;
	background-color: #323232;
	-webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
	transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
	border-radius: 30px;
}

.default-btn:hover, .default-btn:focus {
	color: #ffffff;
}

.default-btn:hover span, .default-btn:focus span {
	width: 225%;
	height: 562.5px;
}

.default-btn-one {
	border: none;
	position: relative;
	display: inline-block;
	text-align: center;
	overflow: hidden;
	z-index: 1;
	color: #01a7ec;
	background-color: #ffffff;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 30px;
	font-weight: 600;
	font-size: 15px;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 12px;
	padding-bottom: 12px;
	margin-top: 5px;
	margin-right: 20px;
	box-shadow: 0 7px 25px rgb(123,104,238,0.25);
}

.default-btn-one span {
	position: absolute;
	display: block;
	width: 0;
	height: 0;
	border-radius: 50%;
	background-color: #323232;
	-webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
	transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
	border-radius: 30px;
}

.default-btn-one:hover, .default-btn-one:focus {
	color: #ffffff;
}

.default-btn-one:hover span, .default-btn-one:focus span {
	width: 225%;
	height: 562.5px;
}

.section-title {
	text-align: center;
	margin-bottom: 65px;
	line-height: 1.5;
}

.section-title h6 {
	font-size: 16px;
	font-weight: 600;
	color: #FF9800;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.section-title h2 {
	font-size: 33px;
	color: #505060;
	margin: 0 0 0 0;
	font-weight: 600;
}

.color-text {
    color: #01a7ec;
    font-size: 33px;
    text-transform: capitalize;
}

.valign {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

/* [ Start Overlay ] */
[data-overlay-dark],
[data-overlay-light] {
	position: relative;
}

[data-overlay-dark] .container,
[data-overlay-light] .container {
	position: relative;
	z-index: 2;
}

[data-overlay-dark]:before,
[data-overlay-light]:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
}

[data-overlay-dark]:before {
	background: #02050b;
}

[data-overlay-light]:before {
	background: #fff;
}

[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark span,
.bg-color h1,
.bg-color h2,
.bg-color h3,
.bg-color h4,
.bg-color h5,
.bg-color h6,
.bg-color span {
	color: #fff;
}

[data-overlay-dark] p,
.bg-dark p,
.bg-color p {
	color: #dad6d6;
}

[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
	opacity: 0;
}

[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
	opacity: .1;
}

[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
	opacity: .2;
}

[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
	opacity: .3;
}

[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
	opacity: .4;
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
	opacity: .5;
}

[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
	opacity: .6;
}

[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
	opacity: .7;
}

[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
	opacity: .8;
}

[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before,
[data-overlay-color="9"]:before {
	opacity: .9;
}

[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
	opacity: 1;
}

/* [ End Overlay ] */

/*****************************************
 Go Top CSS
 ******************************************/
.go-top {
	position: fixed;
	cursor: pointer;
	top: 0;
	right: 15px;
	color: #ffffff;
	background-color: #01a7ec;
	text-align: center;
	z-index: 4;
	width: 45px;
	height: 45px;
	line-height: 45px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: .9s;
	transition: .9s;
	border-radius: 3px;
}

.go-top.active {
	top: 97%;
	-webkit-transform: translateY(-98%);
	transform: translateY(-98%);
	opacity: 1;
	visibility: visible;
}

.go-top i {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin: 0 auto;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.go-top i:last-child {
	opacity: 0;
	visibility: hidden;
	top: 60%;
}

.go-top::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: #505060;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 5px;
}

.go-top:hover, .go-top:focus {
	color: #ffffff;
}

.go-top:hover::before, .go-top:focus::before {
	opacity: 1;
	visibility: visible;
}

.go-top:hover i:first-child, .go-top:focus i:first-child {
	opacity: 0;
	top: 0;
	visibility: hidden;
}

.go-top:hover i:last-child, .go-top:focus i:last-child {
	opacity: 1;
	visibility: visible;
	top: 50%;
}

/**************************************
Preloader Area CSS
 **************************************/
.preloader {
    position: fixed;
    z-index: 999999;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    text-align: center;
    left: 0;
    right: 0;
}
.preloader .lds-spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.preloader .lds-spinner div {
    -webkit-transform-origin: 40px 40px;
    transform-origin: 40px 40px;
    -webkit-animation: lds-spinner 1.2s linear infinite;
    animation: lds-spinner 1.2s linear infinite;
}
.preloader .lds-spinner div::after {
    content: " ";
    display: block;
    position: absolute;
    top: 5px;
    left: 35px;
    width: 1px;
    height: 30px;
    border-radius: 1%;
    background: #01a7ec;
}
.preloader .lds-spinner div:nth-child(1){
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg);
    -webkit-animation-delay:-1.1s;
    animation-delay:-1.1s
}
.preloader .lds-spinner div:nth-child(2){
    -webkit-transform:rotate(30deg);
    transform:rotate(30deg);
    -webkit-animation-delay:-1s;
    animation-delay:-1s
}
.preloader .lds-spinner div:nth-child(3){
    -webkit-transform:rotate(60deg);
    transform:rotate(60deg);
    -webkit-animation-delay:-.9s;
    animation-delay:-.9s
}
.preloader .lds-spinner div:nth-child(4){
    -webkit-transform:rotate(90deg);
    transform:rotate(90deg);
    -webkit-animation-delay:-.8s;
    animation-delay:-.8s
}
.preloader .lds-spinner div:nth-child(5){
    -webkit-transform:rotate(120deg);
    transform:rotate(120deg);
    -webkit-animation-delay:-.7s;
    animation-delay:-.7s
}
.preloader .lds-spinner div:nth-child(6){
    -webkit-transform:rotate(150deg);
    transform:rotate(150deg);
    -webkit-animation-delay:-.6s;
    animation-delay:-.6s
}
.preloader .lds-spinner div:nth-child(7){
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
    -webkit-animation-delay:-.5s;
    animation-delay:-.5s
}
.preloader .lds-spinner div:nth-child(8){
    -webkit-transform:rotate(210deg);
    transform:rotate(210deg);
    -webkit-animation-delay:-.4s;
    animation-delay:-.4s
}
.preloader .lds-spinner div:nth-child(9){
    -webkit-transform:rotate(240deg);
    transform:rotate(240deg);
    -webkit-animation-delay:-.3s;
    animation-delay:-.3s
}
.preloader .lds-spinner div:nth-child(10){
    -webkit-transform:rotate(270deg);
    transform:rotate(270deg);
    -webkit-animation-delay:-.2s;
    animation-delay:-.2s
}
.preloader .lds-spinner div:nth-child(11){
    -webkit-transform:rotate(300deg);
    transform:rotate(300deg);
    -webkit-animation-delay:-.1s;
    animation-delay:-.1s
}
.preloader .lds-spinner div:nth-child(12){
    -webkit-transform:rotate(330deg);
    transform:rotate(330deg);
    -webkit-animation-delay:0s;
    animation-delay:0s
}
@-webkit-keyframes lds-spinner{
    0%{
        opacity:1
    }
    100%{
        opacity:0
    }
}
@keyframes lds-spinner{
    0%{
        opacity:1
    }
    100%{
        opacity:0
    }
}

/**************************************
Navbar Area CSS
 **************************************/

.techzone-responsive-nav {
	display: none;
}

.techzone-nav {
	background-color: transparent;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 0;
	padding-left: 0;
}

.techzone-nav .navbar {
	padding-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-bottom: 0;
}

.techzone-nav .navbar ul {
	padding-left: 0;
	list-style-type: none;
	margin-bottom: 0;
}

.techzone-nav .navbar .navbar-nav {
	margin: auto;
	background-image: linear-gradient(177deg, #01a7ec 0%, #098fc7 100%);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 30px;
}

.techzone-nav .navbar .navbar-nav .nav-item {
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 0;
	padding-right: 0;
}

.techzone-nav .navbar .other-option .login{
	color: #ffffff;
}

.techzone-nav.index-navber .navbar .navbar-nav .nav-item a {
	color: #fff;
}

.navbar-area.is-sticky .other-option .login {
	color: #555;
}

.techzone-nav.index-navber .navbar .other-option .login {
	color: #555;
}

.techzone-nav .navbar .navbar-nav .nav-item a {
	font-size: 15px;
	font-weight: 500;
	color: #fff;
	text-transform: capitalize;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
	padding-bottom: 0;
	margin-left: 15px;
	margin-right: 15px;
}
/*
.techzone-nav .navbar .navbar-nav .nav-item a:hover, .techzone-nav .navbar .navbar-nav .nav-item a:focus, .techzone-nav .navbar .navbar-nav .nav-item a.active {
	color: #01a7ec;
}
*/
.techzone-nav .navbar .navbar-nav .nav-item a i {
	font-size: 10px;
	position: relative;
	top: -1px;
	margin-left: 1px;
}

.techzone-nav .navbar .navbar-nav .nav-item:last-child a {
	/* margin-right: 0; */
}

.techzone-nav .navbar .navbar-nav .nav-item:first-child a {
	/* margin-left: 0; */
}
/*
.techzone-nav .navbar .navbar-nav .nav-item:hover a, .techzone-nav .navbar .navbar-nav .nav-item:focus a, .techzone-nav .navbar .navbar-nav .nav-item.active a {
	color: #01a7ec;
}
*/
.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu {
	-webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
	background: #01a7ec;
	position: absolute;
	border: none;
	top: 80px;
	left: 0;
	width: 230px;
	z-index: 99;
	display: block;
	opacity: 0;
	visibility: hidden;
	border-radius: 0;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	padding: 0px;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
	padding: 0;
	border-bottom: 1px solid #f1f1f1;
}
.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child {
	border-bottom: 0px solid transparent;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
	text-transform: capitalize;
	padding: 10px 15px;
	margin: 0;
	position: relative;
	color: #ffffff;
	font-size: 14px;
	font-weight: 500;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
	color: #505060;
	background: #f8f8f8;
	padding-left: 20px;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
	left: -245px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
	color: #696997;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: -245px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #696997;
	text-transform: capitalize;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: -245px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #696997;
	text-transform: capitalize;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: 195px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #696997;
	text-transform: capitalize;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: 195px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #696997;
	text-transform: capitalize;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
	left: 195px;
	top: 0;
	opacity: 0;
	visibility: hidden;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
	color: #696997;
	text-transform: capitalize;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
	color: #01a7ec;
}

.techzone-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: -15px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.techzone-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
	opacity: 1;
	visibility: visible;
	top: 100%;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.techzone-nav .navbar .other-option {
	margin-left: 0;
	display: flex;
	vertical-align: middle;
	align-items: center;
	justify-content: space-around;
}

.techzone-nav .navbar .other-option .default-btn {
    padding: 8px 25px;
    font-size: 14px;
    font-weight: 500;
	box-shadow: 0 2px 15px rgb(123, 104, 238, 0.15);
}

.techzone-nav .navbar .other-option .default-btn.nav-btn-1 {
	margin-right: 15px;
}

.techzone-nav .black-logo {
	display: none;
}

.navbar-area {
	background-color: transparent;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	position: absolute;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	height: auto;
}

.navbar-area.is-sticky {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;
	-webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
	background: #ffffff !important;
	-webkit-animation: 500ms ease-in-out 0s normal fadeInDown;
	animation: 500ms ease-in-out 0s normal fadeInDown;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.navbar-area.is-sticky .navbar-nav .nav-item a {
	color: #505050;
}

.navbar-area.is-sticky .navbar-nav .nav-item a:hover, .navbar-area.is-sticky .navbar-nav .nav-item a:focus, .navbar-area.is-sticky .navbar-nav .nav-item a.active {
	color: #01a7ec;
}

.navbar-area.is-sticky .techzone-nav .navbar-brand .white-logo {
	display: none;
}

.navbar-area.is-sticky .techzone-nav .navbar-brand .black-logo {
	display: block;
}

@media only screen and (max-width: 991px) {
	.navbar-area {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.navbar-area.is-sticky {
		border-bottom: none;
		-webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.navbar-area.is-sticky .logo .white-logo {
		display: none;
	}

	.navbar-area.is-sticky .logo .black-logo {
		display: block;
	}

	.techzone-responsive-nav {
		display: block;
	}

	.techzone-responsive-nav .techzone-responsive-menu {
		position: relative;
	}

	.techzone-responsive-nav .techzone-responsive-menu.mean-container .mean-nav ul {
		font-size: 15px;
	}

	.techzone-responsive-nav .techzone-responsive-menu.mean-container .mean-nav ul li a {
		font-size: 15px;
	}

	.techzone-responsive-nav .techzone-responsive-menu.mean-container .mean-nav ul li a.active {
		color: #01a7ec;
	}

	.techzone-responsive-nav .techzone-responsive-menu.mean-container .mean-nav ul li li a {
		font-size: 15px;
	}

	.techzone-responsive-nav .techzone-responsive-menu.mean-container .navbar-nav {
		overflow-y: scroll;
		height: 300px;
		-webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
	}

	.techzone-responsive-nav .techzone-responsive-menu.mean-container .others-options {
		display: none;
	}

	.techzone-responsive-nav .mean-container a.meanmenu-reveal {
		color: #505060;
	}

	.techzone-responsive-nav .mean-container a.meanmenu-reveal span {
		background: #505060;
	}

	.techzone-responsive-nav .logo {
		position: relative;
		width: 50%;
		z-index: 999;
	}

	.techzone-responsive-nav .logo .white-logo {
		display: block;
	}

	.techzone-responsive-nav .logo .black-logo {
		display: none;
	}

	.techzone-nav {
		display: none;
	}
}

/**************************************
Home Area CSS
 **************************************/
.home-area {
	position: relative;
	z-index: 1;
	height: 650px;
	background: url(../img/home-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.grey-cr-left {
    height: 500px;
    max-width: 500px;
    border-radius: 500px;
    position: absolute;
    bottom: -20%;
    left: -20%;
	z-index: -1;
    background: rgb(215, 215, 215, 0.17);
}

.main-banner-content {
    max-width: 450px;
	padding-top: 50px;
}
.main-banner-content h1 {
	font-size: 27px;
	color: #333;
	margin: 0 0 20px 0;
	font-weight: 600;
}

.main-banner-content p {
	color: #555;
	font-size: 16px;
	margin: 0 0 0 0;
}
.header-subtitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    color: #fff;
    margin-bottom: 40px;
}

.main-banner-content .banner-btn {
	margin-top: 25px;
}

.home-area .banner-image {
    text-align: center;
    padding-top: 70px;
	animation: float 5s ease-in-out infinite;
}

@keyframes float {
		0%{
			transform: translatey(0px);
		}
		50%{
			transform: translatey(-20px);
		}
		100%{
			transform: translatey(0px);
	}
}

.home-area .creative-shape {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
}

/**************************************
Services Section CSS
 *************************************/
.services-section .section-title {
	margin-bottom: 35px;
}

.single-services-item {
	position: relative;
	z-index: 1;
	display: block;
	padding: 35px 30px;
	text-align: center;
	border-radius: 10px;
	background-color: #ffffff;
	margin-top: 30px;
	overflow: hidden;
	-webkit-box-shadow: 0px 2px 25px 0px rgb(110, 110, 110, 0.12);
	-moz-box-shadow: 0px 2px 25px 0px rgb(110, 110, 110, 0.12);
	box-shadow: 0px 2px 25px 0px rgb(110, 110, 110, 0.12);
	-webkit-transition: all 500ms ease-out;
	transition: all 500ms ease-out;
	border-radius: 30px;
	border: 3px dashed #01a7ec;
	overflow: hidden;
}

.services-icon {
    display: inline-block;
    margin-bottom: 25px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    will-change: transform;
}

.services-icon i {
    color: #01a7ec;
    font-size: 70px;
    line-height: 70px;
    display: block;
    margin: auto;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services-item h3 {
	font-size: 18px;
    font-weight: 600;
	margin-bottom: 0;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
}

.single-services-item h3 a {
	display: inline-block;
}

.single-services-item p {
	font-size: 15px;
	margin-top: 15px;
	margin-bottom: 0;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    will-change: transform;
}

.single-services-item .services-btn-link {
	margin-top: 16px;
}

.single-services-item .services-btn-link .services-link {
    display: inline-block;
    font-size: 16px;
    color: #01a7ec;
    letter-spacing: 0.5px;
    font-weight: 600;
    -webkit-transition: all 400ms ease-out;
    transition: all 400ms ease-out;
    text-decoration: underline;
    background: #484646;
}

.single-services-item:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
}

.single-services-item:hover .services-icon i {
    /* background: #fff; */
    color: #fff;
}

.single-services-item:hover h3 {
	color: #fff;
}
.single-services-item:hover p {
	color: #fff;
}
.single-services-item:hover .services-btn-link .services-link {
	color: #fff;
}
.single-services-item:hover .default-btn{
	background-image: linear-gradient(177deg, #fff 0%, #fff 100%);
	color: #01a7ec;
}
.single-services-item:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: -1;
    background-image: linear-gradient(177deg, #01a7ec 0%, #098fc7 100%);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
}
.single-services-item.active:before, .single-services-item:hover:before {
    opacity: 1;
}
.single-services-item.active .services-icon i {
    /* background: #fff; */
    color: #fff;
}

.single-services-item.active h3 {
	color: #fff;
}
.single-services-item.active p {
	color: #fff;
}
.single-services-item.active .services-btn-link .services-link {
	color: #fff;
}
.single-services-item.active .default-btn{
	background-image: linear-gradient(177deg, #fff 0%, #fff 100%);
	color: #01a7ec;
}
/**************************************
Overview Section CSS
 **************************************/
.overview-content h6 {
	font-size: 16px;
	font-weight: 600;
	color: #FF9800;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.overview-content h2 {
	font-size: 33px;
	color: #505060;
	margin: 8px 0 15px 0;
}

.overview-content p {
	margin: 0 0 0 0;
}

.overview-content .features-list {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	list-style-type: none;
	margin-top: 16px;
	margin-left: -10px;
	margin-right: -10px;
	margin-bottom: 16px;
}

.overview-content .features-list li {
	-ms-flex: 0 0 50%;
	-webkit-box-flex: 0;
	flex: 0 0 50%;
	max-width: 100%;
	margin-top: 1px;
	padding-left: 10px;
	padding-right: 10px;
}

.overview-content .features-list li span {
	display: block;
	border-radius: 0px;
	padding: 12px 15px;
	padding-left: 0px;
	z-index: 1;
	position: relative;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	color: #01a7ec;
	font-size: 14px;
	font-weight: 500;
	/* border-left: 5px solid #01a7ec; */
	overflow: hidden;
}

.overview-content .features-list li span:hover {
	color: #ffffff;
	padding-left: 30px;
}
.overview-content .features-list li span.active {
    color: #fff;
	padding-left: 30px;
}

.overview-content .features-list li span::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 100%;
	bottom: 0;
	border-radius: 0px;
	background-color: #01a7ec;
	background-image: linear-gradient(177deg, #01a7ec 0%, #098fc7 100%);
	z-index: -1;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.overview-content .features-list li span.active:before {
    right: 0;
}
.overview-content .features-list li span:hover::before {
	right: 0;
}

.overview-image {
	text-align: center;
}
.overview-image-2{
	border-radius: 30px;
    border: 3px dashed #01a7ec;
    overflow: hidden;
}

/**************************************
Counter Section CSS
 *************************************/
.counter-area {
	text-align: center;
	position: relative;
	color: #fff;
	text-align: center;
	background: url(../img/banner-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	z-index: 1;
}
.counter-area::after {
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	opacity: .5;
	background-color: #383838;
	background-image: linear-gradient(177deg, #01a7ec 0%, #01a7ec 100%);
}
.counter-area .section-title h5 {
	color: #ffffff;
}
.counter-area .section-title h2 {
	color: #fff;
}
.counter-contents h2 {
	color: #fff;
	font-size: 45px;
	font-weight: 600;
	margin-bottom: 5px;
	word-spacing: -10px;
}
.counter-contents h3 {
	color: #fff;
	font-size: 18px;
	font-weight: 600;
}

/**************************************
Testimonial Section CSS
 **************************************/
.testimonial-section {
	text-align: center;
	position: relative;
	z-index: 1;
}

.single-testimonial {
	position: relative;
	background: #fff;
	padding: 30px 15px;
	border-radius: 5px;
	border-radius: 30px;
	border: 3px dashed #01a7ec;
	overflow: hidden;
}

.single-testimonial .testimonial-content {
	text-align: center;
}

.single-testimonial .rating-box {
	margin-bottom: 10px;
}
.rating-box ul li {
	display: inline-block;
	margin-right: 0px;
}

.rating-box ul li i {
	color: #ffce39;
	font-size: 16px;
}

.single-testimonial .testimonial-content p {
	font-size: 15px;
	line-height: 1.8;
	letter-spacing: 1px;
}

.single-testimonial .avatar {
	margin: 0 auto;
	margin-bottom: 15px;
}

.single-testimonial .avatar img {
	border-radius: 50%;
	border: 5px solid #f8f8f8;
	width: 90px;
	margin: 0 auto;
}

.single-testimonial .testimonial-bio {
	text-align: center;
}

.single-testimonial .testimonial-bio .bio-info h3 {
	color: #333;
	font-size: 18px;
	font-weight: 600;
	margin-top: 0;
}

.single-testimonial .testimonial-bio .bio-info span {
	color: #333;
	font-size: 15px;
	font-weight: 500;
}

.testimonial-slider.owl-theme .owl-nav {
	opacity: 0;
	visibility: hidden;
	margin-top: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.testimonial-slider.owl-theme .owl-nav [className*=owl-] {
	position: absolute;
	left: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0;
	padding: 0;
	background-color: #fff;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	color: #333;
	font-size: 18px;
	width: 50px;
	height: 45px;
	line-height: 50px;
}

.testimonial-slider.owl-theme .owl-nav [className*=owl-].owl-next {
	left: auto;
	right: 10px;
}

.testimonial-slider.owl-theme .owl-nav [className*=owl-]:hover {
	color: #01a7ec;
	background-color: transparent;
}

.testimonial-slider.owl-theme .owl-dots {
	line-height: .01;
	margin-top: 30px;
	margin-bottom: 0;
}

.testimonial-slider.owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 0 4px;
	background-color: #fff;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 30px;
}

.testimonial-slider.owl-theme .owl-dots .owl-dot:hover span {
	background-color: #01a7ec;
}

.testimonial-slider.owl-theme .owl-dots .owl-dot.active span {
	background-color: #01a7ec;
}

.testimonial-slider.owl-theme:hover .owl-nav {
	opacity: 1;
	visibility: visible;
}

/**************************************
Team Section CSS
 **************************************/
.team-area {
}

.team-area .section-title {
	margin-bottom: 35px;
}
.single-team-box {
	margin-top: 30px;
	overflow: hidden;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}
.team-image {
	position: relative;
	z-index: 1;
	border-radius: 3px;
	overflow: hidden;
	border-radius: 30px;
	border: 3px dashed #01a7ec;
	overflow: hidden;
}


.single-team-box .team-image img {
	width: 100%;
	height: auto;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}

.single-team-box:hover .team-image img {
	-webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

.team-social-icon {
    position: absolute;
    z-index: 5;
    bottom: 5%;
    width: 74px;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.team-social-icon a {
    margin: 5px;
    color: #01a7ec;
    font-size: 16px;
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    will-change: transform;
}
.team-social-icon a i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #ffffff;
    border-radius: 3px;
}
.single-team-box:hover .team-social-icon a {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.team-info {
	padding: 20px 15px 0px;
	text-align: center;
	-webkit-transition: 0.5s;
	transition: 0.5s;
}
.team-info h3 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 0;
}
.team-info span {
	color: #01a7ec;
	font-size: 15px;
	font-weight: 500;
	display: block;
}

/**************************************
Hire Section CSS
 **************************************/
.hire-section {
	position: relative;
	z-index: 1;
	background-image: url(../img/banner-bg.jpg);
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	padding: 70px 0;
}

.hire-section::before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 0.4;
	background-color: #383838;
	background-image: linear-gradient(177deg, #01a7ec 0%, #01a7ec 100%);;
}

.hire-content h6 {
	font-size: 16px;
	font-weight: 600;
	color: #FF9800;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.hire-content h2 {
	font-size: 35px;
	color: #fff;
	margin: 0;
	font-weight: 600;
}

.hire-content p {
	color: #fff;
	font-size: 16px;
	margin: 20px 0 0 0;
}

.hire-content .hire-btn {
	margin-top: 20px;
}

.hire-content .hire-btn .default-btn {
    margin-right: 15px;
}

/**************************************
Blog Section CSS
 **************************************/
.blog-item {
	border-radius: 3px;
	overflow: hidden;
	-webkit-box-shadow: -1px 5px 20px 0px rgb(82 90 101 / 10%);
	box-shadow: -1px 5px 20px 0px rgb(82 90 101 / 10%);
	background-color: #ffffff;
	position: relative;
	margin-bottom: 30px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 30px;
	border: 3px dashed #01a7ec;
	overflow: hidden;
}

.blog-item .single-blog-item {
	border-top: none;
	text-align: center;
	padding: 20px 25px;
}

.blog-item .single-blog-item .blog-list {
	padding-left: 0;
	margin-bottom: 5px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.blog-item .single-blog-item .blog-list li {
	list-style-type: none;
	display: inline-block;
	font-size: 13px;
	font-weight: 400;
	margin-right: 15px;
	color: #01a7ec;
}

.blog-item .single-blog-item .blog-list li:last-child {
	margin-right: 0;
}

.blog-item .single-blog-item .blog-list li i {
	margin-right: 3px;
	position: relative;
	top: -1px;
}

.blog-item .single-blog-item .blog-list li i::before {
	font-size: 15px;
}

.blog-item .single-blog-item .blog-list a {
	color: #01a7ec;
	text-decoration: none;
}

.blog-item .single-blog-item .blog-content {
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.blog-item .single-blog-item .blog-content h3 {
	color: #505060;
	font-size: 18px;
	margin-bottom: 10px;
	line-height: 25px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	line-height: 32px;
	font-weight:500;
}

.blog-item .single-blog-item .blog-content h3:hover {
	color: #01a7ec;
	-webkit-transition: .6s;
	transition: .6s;
	-webkit-transition: 0.5s;
	text-decoration: none;
}

.blog-item .single-blog-item .blog-content p {
	margin: 0;
	font-size: 15px;
	color: #6a6c72;
	font-weight: 400;
}

.blog-item .single-blog-item .blog-content a {
	text-decoration: none;
}

.blog-item .single-blog-item .blog-content .blog-btn {
	margin-top: 15px;
}

.blog-item .single-blog-item .blog-content .blog-btn .blog-btn-one {
	display: inline-block;
	font-size: 15px;
	color: #fff;
	font-weight: 500;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.blog-item:hover .blog-content h3 {
	color: #01a7ec;
}

/**************************************
Partner Section CSS
 **************************************/
.partner-section {
	position: relative;
	background-color: #fff;
	overflow: hidden;
	z-index: 1;
}

.partner-list {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
}

.partner-list .partner-item {
	-ms-flex: 0 0 20%;
	-webkit-box-flex: 0;
	flex: 0 0 20%;
	max-width: 20%;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 20px;
}

.partner-list .partner-item a {
	display: block;
	text-align: center;
	position: relative;
	padding: 0px 0px;
	border: 1px solid #ebebeb;
}

/**************************************
Partner Slider CSS
 *************************************/
.partner-area {
	padding: 50px 0px;
}

.partner-slide-item {
	padding: 0px 0px;
	margin: 0px 15px;
}

.partner-area .partner-carousel img {
	max-width: 100%;
	width: inherit!important;
	margin: 0 auto;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.partner-area .partner-carousel img:hover {
	opacity: .7;
	cursor: pointer;
}

/**********************************************
Other Page Banner Title CSS
 **********************************************/
.page-title-area {
	position: relative;
	z-index: 1;
	background-image: url(../img/page-title-bg.jpg);
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 270px;
}

.page-title-area::before {
	content: "";
	position: absolute;
	z-index: -1;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	opacity: 0.5;
	background-color: #383838;
	background-image: linear-gradient(177deg, #01a7ec 0%, #01a7ec 100%);
}

.page-title-content {
	text-align: left;
	margin-top: 45px;
}

.page-title-content h2 {
	margin-bottom: 0;
	color: #ffffff;
	font-size: 42px;
	font-weight: 600;
}

.page-title-content ul {
	padding-left: 0;
	list-style-type: none;
	margin-top: 12px;
	margin-bottom: 0;
}

.page-title-content ul li {
	color: #ffffff;
	display: inline-block;
	position: relative;
	font-weight: 400;
	font-size: 17px;
	margin-left: 10px;
	margin-right: 10px;
}

.page-title-content ul li a {
	display: inline-block;
	color: #ffffff;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.page-title-content ul li a:hover {
	color: #01a7ec;
}

.page-title-content ul li::before {
	content: '';
	position: absolute;
	right: -15px;
	top: 11px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #01a7ec;
}

.page-title-content ul li:last-child::before {
	display: none;
}

.item-bg1 {
	background-image: url(../img/page-title-bg.jpg);
}

.item-bg2 {
	background-image: url(../img/page-title-bg.jpg);
}

/**************************************
About Page CSS
 **************************************/
.about-area {
	position: relative;
	z-index: 1;
}
.about-content {
    margin-right: 60px;
}
.about-content h6 {
	color: #01a7ec;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}
.about-content h2 {
	font-size: 33px;
	font-weight: 600;
	margin-bottom: 20px;
}

.about-content-text p {
	margin-bottom: 15px;
}

.about-area .skills {
	margin-top: 30px;
}
.about-area .skills .skill-item {
	margin-bottom: 25px;
}

.about-area .skills .skill-item:last-child {
	margin-bottom: 0px;
}

.about-area .skills .skill-item h6 {
	position: relative;
	z-index: 4;
	font-size: 15px;
	letter-spacing: 0.7px;
	text-transform: capitalize;
	font-weight: 600;
	margin-bottom: 10px;
}
.about-area .skills .skill-item h6 em {
	float: right;
	font-size: 15px;
	font-style: normal;
}
.about-area .skills .skill-item .skill-progress {
	position: relative;
	height: 5px;
	border-radius: 5px;
	background: #f1f1fa;
}
.about-area .skills .skill-item .skill-progress .progres {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 10%;
	background-color: #01a7ec;
	-webkit-transition: all 1.5s;
	transition: all 1.5s;
}

.about-image {
    position: relative;
    text-align: center;
    margin-right: 40px;
    border-radius: 30px;
    /* border: 3px dashed #01a7ec; */
    overflow: hidden;
}
.about-image img {
	border-radius: 3px;
	/* box-shadow: 0px 10px 60px rgb(0, 0, 0, 0.2); */
}
.about-image:before {
    display: block;
    content: '';
    position: absolute;
    right: -55px;
    top: 55px;
    visibility: visible;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background: transparent; */
    /* background-repeat: repeat; */
}

/**************************************
Projects Page CSS
 **************************************/

.single-project-item {
	position: relative;
	margin-bottom: 30px;
}

.single-project-item::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	background-color: #020202;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.project-image img {
	border-radius: 5px;
}

.single-project-item .project-image-hover-content {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0;
	text-align: left;
	color: #ffffff;
	-webkit-transform: translateY(50px);
	transform: translateY(50px);
	font-size: 20px;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	height: 100%;
}

.single-project-item .project-image-hover-content:hover {
	text-decoration: none;
}

.single-project-item .project-image-hover-content .image-overlay-text {
	background: #000000;
	padding: 20px 20px;
	border-radius: 5px;
	text-align: center;
	will-change: transform;
	height: 100%;
}

.single-project-item .project-image-hover-content .image-overlay-text h3 {
	color: #fff;
	font-size: 18px;
	margin-bottom: 15px;
	line-height: 1.3;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.single-project-item .project-image-hover-content .image-overlay-text h3 a {
	color: #fff;
	font-size: 18px;
	margin: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.single-project-item .project-image-hover-content .image-overlay-text .read-more {
	padding: 10px 30px;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0 2px 15px rgb(123, 104, 238, 0.15);
	display: block;
}


.single-project-item:hover::before, .single-project-item:focus::before {
	opacity: 0.7;
	visibility: visible;
}

.single-project-item:hover .project-image-hover-content, .single-project-item:focus .project-image-hover-content {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition: .6s;
	transition: .6s;
}

/************************************************
Projects Details Page CSS
 ************************************************/
.project-details-image {
	margin-bottom: 30px;
}

.projects-details-desc {
	margin-top: 5px;
}

.projects-details-desc h3 {
	margin-bottom: 13px;
	font-size: 25px;
}

.projects-details-desc .features-text {
	margin-top: 25px;
	margin-bottom: 25px;
}

.projects-details-desc .features-text h4 {
	margin-bottom: 12px;
	font-size: 20px;
	font-weight: 600;
}

.projects-details-desc .features-text h4 i {
	font-size: 16px;
	margin-right: 4px;
	color: #01a7ec;
}

.projects-details-desc .project-details-info {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
	margin-top: 35px;
}

.projects-details-desc .project-details-info .single-info-box {
	-ms-flex: 0 0 20%;
	-webkit-box-flex: 0;
	flex: 0 0 20%;
	max-width: 20%;
	padding-left: 15px;
	padding-right: 15px;
}

.projects-details-desc .project-details-info .single-info-box h4 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 15px;
}

.projects-details-desc .project-details-info .single-info-box span {
	display: block;
	color: #5f5f5f;
	font-size: 15px;
}

.projects-details-desc .project-details-info .single-info-box .social {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.projects-details-desc .project-details-info .single-info-box .social li {
	display: inline-block;
	margin-right: 8px;
}

.projects-details-desc .project-details-info .single-info-box .social li a {
	color: #01a7ec;
	display: inline-block;
	background: transparent;
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	border: 1px dashed #01a7ec;
	border-radius: 25px;
}

.projects-details-desc .project-details-info .single-info-box .social li a:hover {
	color: #fff;
	background-color: #01a7ec;
}

.projects-details-desc .project-details-info .single-info-box .default-btn {
	padding: 10px 30px;
}

/************************************************
Services Details Page CSS
 ************************************************/
.services-details-overview {
	margin-bottom: 60px;
}

.services-details-overview:last-child {
	margin-bottom: 0;
}

.services-step-image {
    margin-bottom: 50px;
}

.services-step-title {
    text-align: left;
    margin: 30px 0;
}

.services-step-title h2 {
	font-size: 25px;
	color: #505060;
	font-weight: 600;
}

.features-text {
	margin-bottom: 20px;
}

.features-text:last-child {
	margin-bottom: 0px;
}

.features-text h4 {
	font-size: 20px;
}

.image-sliders.owl-theme .owl-nav {
	opacity: 0;
	visibility: hidden;
	margin-top: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.image-sliders.owl-theme .owl-nav [className*=owl-] {
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: 0 15px;
	padding: 0;
	background-color: transparent;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	color: #ffffff;
	font-size: 25px;
}

.image-sliders.owl-theme .owl-nav [className*=owl-].owl-next {
	left: auto;
	right: 0;
}

.image-sliders.owl-theme .owl-nav [className*=owl-]:hover {
	color: #01a7ec;
	background-color: transparent;
}

.image-sliders.owl-theme:hover .owl-nav {
	opacity: 1;
	visibility: visible;
}

/**************************************
Pricing Page CSS
 *************************************/

.price-area .section-title {
	text-align: center;
	margin-bottom: 45px;
}

.pricing-content {
    padding: 45px 40px;
    background: #ffffff;
    text-align: left;
    border-radius: 30px;
    /* border: 3px dashed #01a7ec; */
    margin-bottom: 30px;
	box-shadow: -1px 3px 20px 0px rgb(1 167 236 / 25%);
    transition: all 0.4s ease 0s;
}
.pricing-content:hover{
	box-shadow: -1px 50px 100px 0px rgb(1 167 236 / 25%);
	transition: all 0.4s ease 0s;
}
.pricing-content.active{
	box-shadow: -1px 3px 20px 0px rgb(1 167 236 / 25%);
	transition: all 0.4s ease 0s;
}
.price-heading {
    text-align: center;
}
.price-heading .price-name h3 {
    font-size: 24px;
    color: #333;
    font-weight: 700;
    margin-bottom: 15px;
}

.price-heading .price-name h2 {
    font-size: 45px;
    font-weight: 700;
    color: #01a7ec;
	display: inline-block;
}

.price-heading .price-name .price-small-text {
    font-size: 15px;
    font-weight: 600;
    color: #3e3e3e;
}
.price-body {
    padding-left: 40px;
    padding-right: 30px;
}
.price-body ul li {
    display: block;
    padding: 5px 0;
}
.price-body ul li:last-child {
    padding-bottom: 0;
}
.price-body ul li i {
    color: #4caf50;
    padding-right: 5px;
}
.price-body .offer-list-none {
    color: #b7b7b7;
}
.price-body .offer-list-none i {
    color: #ff9aa0;
}
.price-btn {
    text-align: center;
    margin-top: 30px;
}
.price-btn .default-btn {
    padding: 10px 30px;
    display: block;
}

/**************************************
Faq Page CSS
 **************************************/
.faq-accordion .accordion {
	list-style-type: none;
	padding: 0;
	margin: 0;
	padding-left: 0;
	margin-bottom: 0;
	position: relative;
}

.faq-accordion .accordion .accordion-item {
	display: block;
    margin-bottom: 0px;
    border-bottom: 1px solid rgb(231 231 231);
}

.faq-accordion .accordion .accordion-item:last-child {
	margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-title {
    color: #333;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    display: block;
    padding: 25px 0;
    background-color: transparent;
    border-radius: 0;
    cursor: pointer;
}

.faq-accordion .accordion .accordion-item .accordion-title:hover {
    color: #01a7ec;
}
.faq-accordion .accordion .accordion-item .active.accordion-title {
    color: #01a7ec;
}

.faq-accordion .accordion .accordion-item .accordion-title i {
	position: absolute;
	right: 15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #333;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	font-size: 15px;
}

.faq-accordion .accordion .accordion-item .active.accordion-title i {
	color: #01a7ec;
}

.faq-accordion .accordion .accordion-item .accordion-title.active i::before {
	content: "\f0d8";
}

.faq-accordion .accordion .accordion-item .accordion-content {
	display: none;
    font-size: 15px;
    padding-bottom: 20px;
	margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-content.show {
	display: block;
}

/**************************************
Coming Soon Page CSS
 **************************************/
.coming-soon-area {
	position: relative;
	z-index: 1;
	height: 100vh;
	background-image: url(../img/banner-bg.jpg);
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.coming-soon-area::before {
	position: absolute;
	content: '';
	z-index: -1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: .5;
	background-color: #242424;
	background-image: linear-gradient(-135deg, #000000 0%, #080808 100%);
}

.coming-soon-area .social-list {
	padding: 0;
	margin: 0;
	list-style-type: none;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	bottom: 30px;
}

.coming-soon-area .social-list li {
	display: inline-block;
}

.coming-soon-area .social-list li.list-heading {
	display: block;
	color: #ffffff;
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
}

.coming-soon-area .social-list li a {
	width: 35px;
	height: 35px;
	line-height: 35px;
	background: #01a7ec;
	border: 2px solid #01a7ec;
	color: #ffffff;
	border-radius: 50%;
	margin: 0 2px;
	display: inline-block;
}

.coming-soon-area .social-list li a:hover {
	background-color: transparent;
	color: #01a7ec;
	-webkit-transition: .6s;
	transition: .6s;
	-webkit-transition: 0.5s;
}

.social-list p{
	color: #fff;
}

.coming-soon-content {
	text-align: center;
	max-width: 820px;
	margin: -80px auto 0;
}

.coming-soon-content h1 {
	color: #ffffff;
	margin-bottom: 15px;
	font-size: 46px;
	font-weight: 600;

}
.coming-soon-content h2 {
	color: #01a7ec;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.coming-soon-content p {
	color: #ffffff;
	margin: 0 auto;
	max-width: 620px;
	line-height: 30px;
}

.coming-soon-content form {
	position: relative;
	margin: 35px auto 55px;
	max-width: 520px;
}

.coming-soon-content form .email-input {
	display: block;
	width: 100%;
	height: 56px;
	border: none;
	-webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
	box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
	border-radius: 40px;
	padding: 15px 25px;
	outline: 0 !important;
	background: #F1F2F3;
}

.coming-soon-content form .submit-btn {
	position: absolute;
	right: 3px;
	top: 3px;
	height: 50px;
	background: #01a7ec;
	color: #ffffff;
	border: none;
	border-radius: 40px;
	width: 130px;
	outline: 0 !important;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 600;
}

.coming-soon-content form .submit-btn:hover, .coming-soon-content form .submit-btn:focus {
	background-color: #01a7ec;
}

.coming-soon-content #timer div {
	display: inline-block;
	width: 120px;
	height: 120px;
	color: #01a7ec;
	margin: 0 10px;
	padding-top: 18px;
	font-size: 40px;
	font-weight: 600;
}

.coming-soon-content #timer div span {
	display: block;
	margin-top: -4px;
	color: #777777;
	font-size: 15px;
	font-weight: 600;
}

/**************************************
Contact Page CSS
 **************************************/
.contact-section .contact-form-3 form .form-control {
    font-size: 15px;
    width: 100%;
    padding: 10px 20px;
    height: 52px;
    color: #333;
    border: none;
    background-color: #f3f3f3;
    font-weight: 600;
    border-radius: 5px;
    text-transform: capitalize;
    border-left: 2px solid #01a7ec;
    transition: all 0.4s;
}
.contact-section .contact-form-3 form input:focus,
.contact-section .contact-form-3 form textarea:focus {
	outline: none;
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-left: 2px solid #01a7ec;
}
.contact-information-box-3 {
    background: #181818;
    padding: 25px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05), 0 5px 15px rgba(0, 0, 0, 0.03);
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05), 0 5px 15px rgba(0, 0, 0, 0.03);
    margin-bottom: 50px;
}
.contact-information-box-3 .single-contact-info-box {
    position: relative;
    z-index: 1;
    padding: 10px 10px;
    display: block;
    border-radius: 3px;
    text-align: left;
    margin-bottom: 10px;
    overflow: hidden;
}
.contact-information-box-3 .contact-info {
	position: relative;
    z-index: 1;
}

.contact-information-box-3 .contact-info h6 {
	font-weight: 600;
	font-size: 18px;
	color: #01a7ec;
	margin-bottom: 7px;
	text-transform: capitalize;
}

.contact-information-box-3 .contact-info p {
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	margin: 0;
}
.contact-information-box-3 .single-contact-info-box .contact-info-bg-icon {
    color: #01a7ec;
    opacity: .2;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    margin: 0 auto;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.contact-information-box-3 .single-contact-info-box .contact-info-bg-icon i {
    font-size: 100px;
}
.contact-section .contact-form .form-group {
    margin-bottom: 30px;
}
.contact-section .contact-form form textarea.form-control {
    height: auto;
    padding-top: 15px;
    line-height: initial;
}

.contact-section .form-message {
	margin: 0;
}
.contact-section .form-message.success {
	background: #03b103;
	color: #fff;
	padding: 10px 15px;
	border-radius: 3px;
	margin-bottom: 30px;
}

.contact-section .form-message.error {
	background: #ff4d15;
	color: #fff;
	padding: 10px 15px;
	border-radius: 3px;
	margin-bottom: 30px;
}
/**************************************
 ** Map Section Style
 *************************************/
.map-content #contact-map {
	position: relative;
	width: 100%;
	height: 500px;
	border: 0;
	margin-bottom: -2px;
}

/**************************************
Blog Details Page CSS
 **************************************/
.blog-details-desc .article-content {
	margin-top: 30px;
}

.blog-details-desc .article-content .entry-meta {
	margin-bottom: -8px;
}

.blog-details-desc .article-content .entry-meta ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.blog-details-desc .article-content .entry-meta ul li {
	position: relative;
	display: inline-block;
	color: #505060;
	margin-right: 21px;
}

.blog-details-desc .article-content .entry-meta ul li span {
	display: inline-block;
	color: #505060;
	font-weight: 500;
}

.blog-details-desc .article-content .entry-meta ul li a {
	display: inline-block;
	color: #5f5f5f;
}

.blog-details-desc .article-content .entry-meta ul li a:hover {
	color: #01a7ec;
}

.blog-details-desc .article-content .entry-meta ul li i {
	color: #01a7ec;
	margin-right: 2px;
}

.blog-details-desc .article-content .entry-meta ul li::before {
	content: '';
	position: absolute;
	top: 12px;
	right: -15px;
	width: 6px;
	height: 1px;
	background: #01a7ec;
}

.blog-details-desc .article-content .entry-meta ul li:last-child {
	margin-right: 0;
}

.blog-details-desc .article-content .entry-meta ul li:last-child::before {
	display: none;
}

.blog-details-desc .article-content h3 {
	margin-bottom: 15px;
	margin-top: 25px;
	font-size: 24px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 {
	padding-left: 0;
	list-style-type: none;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -10px;
	margin-left: -10px;
	margin-bottom: 30px;
	margin-top: 30px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
	-ms-flex: 0 50%;
	-webkit-box-flex: 0;
	flex: 0 50%;
	max-width: 50%;
	padding-right: 10px;
	padding-left: 10px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
	margin-bottom: 0;
}

.blog-details-desc .article-footer {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 30px;
}

.blog-details-desc .article-footer .article-tags {
	-ms-flex: 0 0 50%;
	-webkit-box-flex: 0;
	flex: 0 0 50%;
	max-width: 50%;
	color: #5f5f5f;
}

.blog-details-desc .article-footer .article-tags span {
	display: inline-block;
	color: #333;
	font-size: 15px;
	font-weight: 600;
	margin-right: 5px;
}

.blog-details-desc .article-footer .article-tags a:last-child {
    margin-right: 0px;
}
.blog-details-desc .article-footer .article-tags a {
    display: inline-block;
    color: #505060;
    font-weight: 400;
    font-size: 14px !important;
    padding: 6px 13px;
    border: 1px solid #ebebeb;
    background: #f8f8f8;
    margin-top: 8px;
    margin-right: 4px;
}

.blog-details-desc .article-footer .article-tags a:hover {
    color: #ffffff;
    background-color: #01a7ec;
    border-color: #01a7ec;
}

.blog-details-desc .article-footer .article-share {
	-ms-flex: 0 0 50%;
	-webkit-box-flex: 0;
	flex: 0 0 50%;
	max-width: 50%;
}

.blog-details-desc .article-footer .article-share .social {
	padding-left: 0;
	list-style-type: none;
	text-align: right;
	margin-bottom: 0;
}

.blog-details-desc .article-footer .article-share .social li {
	display: inline-block;
	margin-right: 5px;
}
.blog-details-desc .article-footer .article-share .social li:last-child {
	margin-right: 0;
}

.blog-details-desc .article-footer .article-share .social li span {
	display: inline-block;
	margin-right: 5px;
	color: #333;
	font-size: 15px;
	font-weight: 600;
}

.blog-details-desc .article-footer .article-share .social li a {
	display: block;
	color: #01a7ec;
	width: 30px;
	height: 30px;
	line-height: 30px;
	border-radius: 5px;
	background-color: #eeeef0;
	text-align: center;
	font-size: 14px;
}

.blog-details-desc .article-footer .article-share .social li a i {
	font-size: 14px;
	line-height: 30px;
}

.blog-details-desc .article-footer .article-share .social li a:hover {
	color: #ffffff;
	background-color: #01a7ec;
}

.blog-details-desc .post-navigation {
	margin-top: 30px;
}

blockquote, .blockquote {
	overflow: hidden;
	background-color: #fafafa;
	padding: 50px !important;
	position: relative;
	text-align: center;
	z-index: 1;
	margin-bottom: 20px;
	margin-top: 20px;
}

blockquote p, .blockquote p {
	color: #505060;
	line-height: 1.6;
	margin-bottom: 0;
	font-style: italic;
	font-weight: 500;
	font-size: 24px !important;
}

blockquote cite, .blockquote cite {
	display: none;
}

blockquote::before, .blockquote::before {
	color: #efefef;
	content: "\f10e";
	position: absolute;
	left: 50px;
	top: -50px;
	z-index: -1;
	font-family: "Font Awesome 5 Free";
	font-size: 140px;
	font-weight: 900;
}

blockquote::after, .blockquote::after {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 3px;
	background-color: #01a7ec;
	margin-top: 20px;
	margin-bottom: 20px;
}

.post-navigation {
	border-top: 1px solid #eeeeee;
	border-bottom: 1px solid #eeeeee;
	padding-top: 20px;
	padding-bottom: 20px;
}

.post-navigation .navigation-links {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.post-navigation .navigation-links .nav-previous {
	-ms-flex: 0 0 50%;
	-webkit-box-flex: 0;
	flex: 0 0 50%;
	max-width: 50%;
}

.post-navigation .navigation-links .nav-previous a i {
	margin-right: 2px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.post-navigation .navigation-links .nav-previous a:hover i {
	margin-right: 0;
}

.post-navigation .navigation-links .nav-next {
	-ms-flex: 0 0 50%;
	-webkit-box-flex: 0;
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
}

.post-navigation .navigation-links .nav-next a i {
	margin-left: 2px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.post-navigation .navigation-links .nav-next a:hover i {
	margin-left: 0;
}

.post-navigation .navigation-links div a {
	display: inline-block;
	font-weight: 600;
}

.comments-area {
	padding: 25px;
	margin-top: 30px;
	-webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
	box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
	background-color: #ffffff;
}

.comments-area .comments-title {
	position: relative;
	margin-bottom: 30px;
	line-height: initial;
	font-size: 24px;
	font-weight: 600;
}

.comments-area ol, .comments-area ul {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.comments-area .comment-list {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.comments-area .comment-body {
	border-bottom: 1px solid #eeeeee;
	padding-left: 65px;
	color: #505060;
	font-size: 14px;
	margin-bottom: 20px;
	padding-bottom: 20px;
}
.comments-area .comment-list .children {
    padding-left: 90px;
}
.comments-area .comment-body .reply {
	margin-top: 15px;
}

.comments-area .comment-body .reply a {
	border: 1px solid #ded9d9;
	color: #505060;
	display: inline-block;
	padding: 5px 20px;
	border-radius: 30px;
	text-transform: capitalize;
	position: relative;
	z-index: 1;
	font-size: 16px;
	font-weight: 600;
}

.comments-area .comment-body .reply a:hover {
	color: #ffffff;
	background-color: #01a7ec;
	border-color: #01a7ec;
}

.comments-area .comment-author {
	font-size: 17px;
	margin-bottom: 0.1em;
	position: relative;
	z-index: 2;
}

.comments-area .comment-author .avatar {
	height: 50px;
	left: -65px;
	position: absolute;
	width: 50px;
}

.comments-area .comment-author .fn {
	font-weight: 600;
}

.comments-area .comment-author .says {
	display: none;
}

.comments-area .comment-metadata {
	margin-bottom: .8em;
	color: #5f5f5f;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 400;
}

.comments-area .comment-metadata a {
	color: #5f5f5f;
}

.comments-area .comment-metadata a:hover {
	color: #01a7ec;
}

.comments-area .comment-respond {
	margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
	margin-bottom: 0;
	position: relative;
	font-size: 24px;
	font-weight: 600;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
	display: inline-block;
}

.comments-area .comment-respond .comment-form {
	overflow: hidden;
}

.comments-area .comment-respond .comment-notes {
	margin-bottom: 0;
	margin-top: 10px;
}

.comments-area .comment-respond .comment-form-comment {
	margin-top: 20px;
    float: left;
    width: 100%;
    margin-bottom: 25px;
}

.comments-area .comment-respond label {
	display: block;
	font-weight: 600;
	color: #505060;
	margin-bottom: 5px;
}

.comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
	display: block;
	width: 100%;
	background-color: #ffffff;
	border: 1px solid #eeeeee;
	padding: 0.625em 0.7375em;
	outline: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.comments-area .comment-respond input[type="date"]:focus, .comments-area .comment-respond input[type="time"]:focus, .comments-area .comment-respond input[type="datetime-local"]:focus, .comments-area .comment-respond input[type="week"]:focus, .comments-area .comment-respond input[type="month"]:focus, .comments-area .comment-respond input[type="text"]:focus, .comments-area .comment-respond input[type="email"]:focus, .comments-area .comment-respond input[type="url"]:focus, .comments-area .comment-respond input[type="password"]:focus, .comments-area .comment-respond input[type="search"]:focus, .comments-area .comment-respond input[type="tel"]:focus, .comments-area .comment-respond input[type="number"]:focus, .comments-area .comment-respond textarea:focus {
	border-color: #01a7ec;
}

.comments-area .comment-respond .comment-form-author {
	float: left;
    width: 50%;
    padding-right: 10px;
    margin-top: 20px;
    margin-bottom: 0;
}

.comments-area .comment-respond .comment-form-email {
    float: left;
    width: 50%;
    padding-left: 12px;
    margin-top: 20px;
    margin-bottom: 0;
}

.comments-area .comment-respond .form-submit {
	float: left;
	width: 100%;
}

.comments-area .comment-respond .form-submit input {
	background: #01a7ec;
	border: none;
	color: #ffffff;
	padding: 10px 30px;
	display: inline-block;
	cursor: pointer;
	outline: 0;
	border-radius: 0;
	text-transform: uppercase;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	font-weight: 600;
	font-size: 14px;
}

.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
	color: #ffffff;
	background-color: #505060;
}

/************************************************
Blog Page Sidebar Widget CSS
 ************************************************/
.widget-area .widget {
	margin-top: 35px;
}

.widget-area .widget:first-child {
	margin-top: 0;
}

.widget-area .widget .widget-title {
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 10px;
	margin-bottom: 25px;
	text-transform: capitalize;
	position: relative;
	font-size: 20px;
}

.widget-area .widget .widget-title::before {
	content: '';
	position: absolute;
	background: #01a7ec;
	bottom: -1px;
	left: 0;
	width: 50px;
	height: 1px;
}

.widget-area .widget_search {
	background-color: #f5f5f5;
	padding: 15px;
}

.widget-area .widget_search form {
	position: relative;
}

.widget-area .widget_search form label {
	display: block;
	margin-bottom: 0;
}

.widget-area .widget_search form .screen-reader-text {
	display: none;
}

.widget-area .widget_search form .search-field {
	background-color: transparent;
	height: 50px;
	padding: 6px 15px;
	border: 1px solid #01a7ec;
	width: 100%;
	display: block;
	outline: 0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.widget-area .widget_search form .search-field:focus {
	border-color: #01a7ec;
}

.widget-area .widget_search form button {
	position: absolute;
	right: 0;
	outline: 0;
	bottom: 0;
	height: 50px;
	width: 50px;
	z-index: 1;
	border: none;
	color: #ffffff;
	background-color: #01a7ec;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.widget-area .widget_search form button:hover {
	background-color: #505060;
	color: #ffffff;
}

.widget-area .widget_techloop_posts_thumb {
	position: relative;
	overflow: hidden;
}

.widget-area .widget_techloop_posts_thumb .item {
	overflow: hidden;
	margin-bottom: 15px;
}

.widget-area .widget_techloop_posts_thumb .item:last-child {
	margin-bottom: 0;
}

.widget-area .widget_techloop_posts_thumb .item .thumb {
	float: left;
	height: 80px;
	overflow: hidden;
	position: relative;
	width: 80px;
	margin-right: 15px;
}

.widget-area .widget_techloop_posts_thumb .item .thumb .fullimage {
	width: 80px;
	height: 80px;
	display: inline-block;
	background-size: cover !important;
	background-repeat: no-repeat;
	background-position: center center !important;
}

.widget-area .widget_techloop_posts_thumb .item .thumb .fullimage.bg1 {
	/* background-image: url(../img/blog-details/blog-details-1.jpg); */
}

.widget-area .widget_techloop_posts_thumb .item .thumb .fullimage.bg2 {
	/* background-image: url(../img/blog-details/blog-details-2.jpg); */
}

.widget-area .widget_techloop_posts_thumb .item .thumb .fullimage.bg3 {
	/* background-image: url(../img/blog-details/blog-details-3.jpg); */
}

.widget-area .widget_techloop_posts_thumb .item .info {
	overflow: hidden;
	margin-top: 5px;
}

.widget-area .widget_techloop_posts_thumb .item .info span {
	display: block;
	color: #5f5f5f;
	text-transform: capitalize;
	margin-top: 3px;
	font-size: 14px;
}

.widget-area .widget_techloop_posts_thumb .item .info .title {
	margin-bottom: 0;
	line-height: 1.4;
	font-size: 16px;
}

.widget-area .widget_techloop_posts_thumb .item .info .title a {
	display: inline-block;
}

.widget-area .widget_recent_entries ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.widget-area .widget_recent_entries ul li {
	position: relative;
	margin-bottom: 12px;
	color: #505060;
	padding-left: 14px;
	line-height: 1.5;
	font-weight: 400;
	font-size: 15.5px;
}
.widget-area .widget_recent_entries ul li:last-child {
	margin-bottom: 0;
}

.widget-area .widget_recent_entries ul li::before {
	background: #01a7ec;
	height: 8px;
	width: 8px;
	content: '';
	left: 0;
	top: 7px;
	position: absolute;
	border-radius: 50px;
	border-right-style: inset;
}

.widget-area .widget_recent_entries ul li a {
	display: inline-block;
	color: #505060;
}

.widget-area .widget_recent_entries ul li a:hover {
	color: #01a7ec;
}

.widget-area .widget_recent_entries ul li .post-date {
	display: block;
	font-size: 13px;
	color: #5f5f5f;
	margin-top: 4px;
}

.widget-area .widget_categories ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.widget-area .widget_categories ul li {
	margin-bottom: 12px;
	color: #5f5f5f;
	font-size: 15px;
	font-weight: 400;
}

.widget-area .widget_categories ul li:last-child {
	margin-bottom: 0;
}

.widget-area .widget_categories ul li a {
	color: #505060;
    display: flex;
    justify-content: space-between;
    background: #f8f8f8;
    padding: 8px 15px;
}

.widget-area .widget_categories ul li a:hover {
	color: #fff;
    background: #01a7ec;
}

.widget-area .widget_categories ul li .post-count {
	float: right;
}

.widget-area .widget_meta ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.widget-area .widget_meta ul li {
	position: relative;
	margin-bottom: 12px;
	color: #505060;
	padding-left: 14px;
	font-size: 15.5px;
	font-weight: 400;
}

.widget-area .widget_meta ul li:last-child {
	margin-bottom: 0;
}

.widget-area .widget_meta ul li::before {
	background: #01a7ec;
	height: 8px;
	width: 8px;
	content: '';
	left: 0;
	top: 7px;
	position: absolute;
	border-radius: 50px;
	border-right-style: inset;
}

.widget-area .widget_meta ul li a {
	display: inline-block;
	color: #505060;
}

.widget-area .widget_meta ul li a:hover {
	color: #01a7ec;
}

.widget-area .widget_tag_cloud .widget-title {
	margin-bottom: 12px;
}

.widget-area .tagcloud a {
    display: inline-block;
    color: #505060;
    font-weight: 400;
    font-size: 14px !important;
    padding: 6px 13px;
    border: 1px solid #ebebeb;
    background: #f8f8f8;
    margin-top: 8px;
    margin-right: 4px;
}

.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
	color: #ffffff;
	background-color: #01a7ec;
	border-color: #01a7ec;
}

.widget-area .widget_event_details ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}

.widget-area .widget_event_details ul li {
	border-bottom: 1px solid #eeeeee;
	color: #888f96;
	padding-bottom: 10px;
	padding-top: 10px;
}

.widget-area .widget_event_details ul li:first-child {
	padding-top: 0;
}

.widget-area .widget_event_details ul li:last-child {
	border-bottom: none;
	padding-bottom: 0;
}

.widget-area .widget_event_details ul li span {
	display: inline-block;
	color: #505060;
	font-weight: 400;
}

.widget-area .widget_event_details ul li a {
	display: inline-block;
	color: #888f96;
}

.widget-area .widget_event_details ul li a:hover {
	color: #01a7ec;
}

/************************************************
Blog Page Number Pagination CSS
 ************************************************/
.pagination-area {
	margin-top: 20px;
	text-align: center;
}

.pagination-area .page-numbers {
	width: 45px;
	height: 45px;
	margin: 0 3px;
	display: inline-block;
	background-color: #ffffff;
	line-height: 48px;
	color: #505060;
	-webkit-box-shadow: 0 2px 10px 0 #d8dde6;
	box-shadow: 0 2px 10px 0 #d8dde6;
	font-size: 18px;
	font-weight: 600;
}

.pagination-area .page-numbers.current, .pagination-area .page-numbers:hover, .pagination-area .page-numbers:focus {
	background: #01a7ec;
	color: #ffffff;
	-webkit-box-shadow: 0 2px 10px 0 #d8dde6;
	box-shadow: 0 2px 10px 0 #d8dde6;
}

/*********************************************************************
Terms & Conditions - Privacy Policy Page CSS
 *********************************************************************/
.single-privacy h3 {
	font-size: 20px;
	margin: 0 0 12px 0;
}
.single-terms h3 {
	font-size: 20px;
	margin: 0 0 12px 0;
}

/*****************************************
All Page Footer Section CSS
 ******************************************/
.footer-area {
	position: relative;
	z-index: 1;
	background-color: #1c1c23;
}
.footer-area {
	position: relative;
	z-index: 1;
	background-image: url(../img/banner-bg.jpg);
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.footer-area::before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	opacity: 0.5;
	background-color: #383838;
	background-image: linear-gradient(177deg, #01a7ec 0%, #01a7ec 100%);
}
.subscribe-area{
	margin-bottom: 50px;
    border-bottom: 1px solid rgb(8 147 205 / 39%);
    padding-bottom: 50px;
}

.subscribe-area h3,
.subscribe-area p{
	color: #fff;
}

.footer-heading {
	margin-bottom: 15px;
}

.footer-heading h3 {
	font-size: 20px;
	color: #01a7ec;
	font-weight: 600;
	margin: 0 0 0 0;
	position: relative;
	padding-bottom: 10px;
}

.single-footer-widget .footer-social {
	padding-left: 0;
	margin-bottom: 0;
	margin-top: 20px;
}

.single-footer-widget .footer-social li {
	display: inline-block;
	margin-right: 10px;
}

.single-footer-widget .footer-social li:last-child {
	margin-right: 0;
}

.single-footer-widget .footer-social i {
	display: inline-block;
	height: 38px;
	width: 38px;
	line-height: 38px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 5px;
	font-size: 18px;
	color: #01a7ec;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	text-align: center;
}

.single-footer-widget .footer-social i:hover {
	background-color: #01a7ec;
	color: #ffffff;
	border: 1px solid #01a7ec;
}

.single-footer-widget p {
	font-size: 15px;
	color: #ececec;
	font-weight: 400;
}

.single-footer-widget .footer-heading {
	margin-bottom: 15px;
}

.single-footer-widget .footer-heading h3 {
	font-size: 20px;
	color: #01a7ec;
	font-weight: 600;
	margin: 0 0 0 0;
}

.single-footer-widget .footer-quick-links {
	padding-left: 0;
	margin-bottom: 0;
}

.single-footer-widget .footer-quick-links li {
	list-style-type: none;
	padding-bottom: 10px;
}

.single-footer-widget .footer-quick-links li:last-child {
	padding-bottom: 0;
}

.single-footer-widget .footer-quick-links li a {
	display: inline-block;
	color: #ececec;
	font-size: 15px;
	font-weight: 400;
}

.single-footer-widget .footer-quick-links li a:hover {
	color: #fff;
	text-decoration: underline !important;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.single-footer-widget .footer-info-contact {
	margin-bottom: 10px;
}
.single-footer-widget .footer-info-contact strong{
	color: #01a7ec;
}

.single-footer-widget .footer-info-contact:last-child {
	margin-bottom: 0;
}

.single-footer-widget .footer-info-contact h3 {
	font-size: 16px;
	color: #fff;
	font-weight: 600;
	margin: 0 0 10px 0;
}

.single-footer-widget .footer-info-contact span {
	font-size: 15px;
	color: #ececec;
	font-weight: 400;
}

.single-footer-widget .footer-info-contact span a {
	font-size: 15px;
	color: #ececec;
	font-weight: 400;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.single-footer-widget .footer-info-contact span a:hover {
	color: #fff;
}

/**************************************
Footer Subscribe CSS
 **************************************/

.newsletter-form {
	max-width: 580px;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
}

.newsletter-form .input-newsletter {
	display: block;
	width: 100%;
	background-color: #ffffff;
	border: none;
	height: 50px;
	padding-left: 25px;
	border-radius: 5px;
	outline: 0;
	color: #505060;
}

.newsletter-form .input-newsletter::-webkit-input-placeholder {
	color: #5f5f5f;
}

.newsletter-form .input-newsletter:-ms-input-placeholder {
	color: #5f5f5f;
}

.newsletter-form .input-newsletter::-ms-input-placeholder {
	color: #5f5f5f;
}

.newsletter-form .input-newsletter::placeholder {
	color: #5f5f5f;
}

.newsletter-form button {
	position: absolute;
	right: 0;
	top: 0;
	background-color: #01a7ec;
	color: #ffffff;
	border: none;
	height: 50px;
	padding: 0 30px;
	border-radius: 0px 5px 5px 0px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	line-height: 50px;
	text-transform: capitalize;
	font-size: 15px;
}

/*****************************************
All Page Copyright Section CSS
 ******************************************/
.copyright-area {
	background-color: #181818;
	padding: 17px 0;
}

.copyright-area p {
	color: #ececec;
	font-size: 15px;
}

.copyright-area p a {
	color: #ececec;
	display: inline-block;
	font-weight: 600;
}

.copyright-area p a:hover {
	color: #01a7ec;
}

.copyright-area ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
	text-align: right;
}

.copyright-area ul li {
	display: inline-block;
	color: #ececec;
	font-size: 15px;
	position: relative;
	margin-left: 10px;
	margin-right: 10px;
}

.copyright-area ul li a {
	display: inline-block;
	color: #ececec;
}

.copyright-area ul li a:hover {
	color: #01a7ec;
}

.copyright-area ul li::before {
	content: '';
	position: absolute;
	top: 5px;
	right: -13px;
	width: 1px;
	height: 14px;
	background-color: #000000;
}

.copyright-area ul li:last-child {
	margin-right: 0;
}

.copyright-area ul li:last-child::before {
	display: none;
}

.copyright-area ul li:first-child {
	margin-left: 0;
}
.contact-area .form-message.success {
	background: #03b103;
	color: #fff;
	padding: 10px 15px;
	border-radius: 3px;
	display: block;
	margin-bottom: 15px;
}
.contact-area .form-message.error {
	background: #ff4d15;
	color: #fff;
	padding: 10px 15px;
	border-radius: 3px;
	display: block;
	margin-bottom: 15px;
}
.footer-area .widget-area .widget_techloop_posts_thumb .item .info .title a, 
.footer-area .widget-area .widget_techloop_posts_thumb .item .info span {
	color: #fff;
}
.messages{
	margin-top: 30px;
}
.alert-success{
	margin-left: 15px;
}
.hidden{
	display: none;
}

.mobile-menu{
	background-color: #fff;
}

.mobile-menu .nav-link{
	color: #000;
}