@media print {
    /* hide all elements except for the table */
    body * {
      visibility: hidden;
    }
    #order-listing, #order-listing * {
      visibility: visible;
    }
    #order-listing {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  .btn{
     border: #000;
  }